<template>
  <div class="mx-1">
    <export-options
        :exportUrl="exportUrl"
        :repository="repository"
        :resource="resource"
        :tableRef="$refs[tableRef]"
        :filter="filter"
        :options="[
        {
          code: 'EXPOSINCLI',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <table-claims
      :fields="fields"
      repository="claim"
      resource="claims"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['infomodal']"
      noInnerWidth
      noInputPaginator
    >
    </table-claims>
  </div>
</template>

<script>
import TableClaims from "@/components/tables/Claims";
import ExportOptions from "@/components/ExportOptions";
import { toLocaleCurrency } from "@/utils/strings";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [DetailTabAutofetchTableMixin],
  name: "Claim",
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterClaimDetail",
      repository: "claim",
      resource: "claims",
      tableRef: "ClaimTableRef",
      items: [],

      fields: [
        {
          key: "claim_number",
          label: this.getDictionary("claim"),
          sortable: true,
          sortKey: "byAttribute.number",
        },
        {
          key: "occurred_at",
          value: "occurred_at",
          formatter: (value) => toLocaleDate(value),
          label: this.getDictionary("occurred_at", "claim"),
          sortable: true,
          sortKey: "occurred_at",
        },
        {
          key: "insurer_code",
          label: this.getDictionary("number", "insurance_policy"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "claim_type.text",
          label: this.getDictionary("claim_type", "claim"),
          sortKey: "byInsurancePolicy.claim_type",
        },
        {
          key: "settled_total",
          value: (item) => toLocaleCurrency(item.settled_total),
          label: this.getDictionary("settled_total", "claim"),
          sortable: true,
          sortKey: "byAttribute.settled_total",
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  components: {
    TableClaims,
    ExportOptions,
  },
  methods: {
    initFilter() {
      let init = {
        byRegistry: { id: this.resourceId },
      };
      return init;
    },
  },
  props: {
    resourceId: Number,
  },
};
</script>
